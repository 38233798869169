exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-philosophy-jsx": () => import("./../../../src/pages/philosophy.jsx" /* webpackChunkName: "component---src-pages-philosophy-jsx" */),
  "component---src-templates-article-template-jsx": () => import("./../../../src/templates/article-template.jsx" /* webpackChunkName: "component---src-templates-article-template-jsx" */),
  "component---src-templates-journal-template-jsx": () => import("./../../../src/templates/journal-template.jsx" /* webpackChunkName: "component---src-templates-journal-template-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("./../../../src/templates/tag-template.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */)
}

